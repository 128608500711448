<template>
  <vx-card :title="title">
    <vs-tabs :value="activeTab">
      <vs-tab label="New">
        <New></New>
      </vs-tab>
      <vs-tab label="Inquiry">
        <Inquiry></Inquiry>
      </vs-tab>
      <vs-tab label="Waiting Approval">
        <Waiting></Waiting>
      </vs-tab>
      <vs-tab label="Approved">
        <Approved></Approved>
      </vs-tab>
      <vs-tab label="Attached">
        <Attached></Attached>
      </vs-tab>
      <vs-tab label="Executed">
        <Executed></Executed>
      </vs-tab>
      <vs-tab label="Rejected">
        <Rejected></Rejected>
      </vs-tab>
      <vs-tab label="Canceled">
        <Canceled></Canceled>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import New from "./_tab/new-tab.vue";
import Waiting from "./_tab/waiting-tab.vue";
import Approved from "./_tab/approved-tab.vue";
import Executed from "./_tab/executed-tab.vue";
import Rejected from "./_tab/rejected-tab.vue";
import Canceled from "./_tab/canceled-tab.vue";
import Export from "./_tab/Export.vue";
import Inquiry from "./_tab/inquiry-tab.vue";
import Attached from "./_tab/attached-tab.vue";
export default {
  props: {
    active: {
      type: Number,
    },
  },
  components: {
    New,
    Waiting,
    Approved,
    Executed,
    Rejected,
    Canceled,
    Export,
    Inquiry,
    Attached,
  },
  data() {
    return {
      title: "Stock Scrap",
      activeTab : this.$route.query.tab ? this.$route.query.tab : 0
    };
  },
};
</script>
